<template>
    <v-select 
        :multiple="multiple" 
        :filterable="true" 
        :options="options" 
        v-model="selected" 
        :clearable="false"
        :placeholder="placeholder" />
</template>

<script>
import vSelect from "vue-select";
export default {
    components: {
        vSelect,
    },
    props: {
        multiple: {
            type: Boolean,
            default: false,
        },
        draw: {
            type: Number,
        },
        placeholder: {
            type: String
        },
        value: {
            type: Object
        }
    },
    data() {
        return {
            baseUrl: "/api/wms/v1/inter-storage/get-warehouse-options",
            options: [],
            selected: (this.multiple) ? [] : null
        };
    },
    methods: {
        get() {
            // this.$vs.loading();
            this.$http.get(this.baseUrl)
            .then(resp => {
                if (resp.code == 200) {
                    this.options = resp.data.records;
                } else {
                    this.options = []
                    console.log(resp.data);
                }
            })
            .catch(error => {
                console.log(error)
            });
        }
    },
    watch: {
        selected(v) {
            this.selected = v;
            this.$emit("selected", v);
        },
        value(v) {
            if (v) {
                this.selected = v
            }
        }
    },
    mounted() {
        this.get();
    }
};
</script>